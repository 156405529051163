import React, { useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import ViewVideo from "./ViewVideo";
import {
  CampaignService,
  useCampaignState,
} from "../../../../Service/CampaignServices";
import { useParams } from "react-router-dom";
import Loader from "../../../Loader2";
import VideoThumbnail from "react-video-thumbnail";
import CustomButton from "../../../../utils/customButton";

function FinalDeliverables() {
  const { campaign_id } = useParams();
  const [openModel, setOpenModel] = useState(false);
  const campaignState = useCampaignState();
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (campaignState.updateNeed.value) {
      campaignState.merge({ loading: true });
      CampaignService.FetchCampaignDraftsForBrand(campaign_id);
    }
  }, [campaignState.updateNeed.value]);

  const campaign = campaignState.campaign.value;

  const tasks =
    Object.keys(campaign.tasks).length !== 0
      ? Object.values(campaign.tasks)
      : [];

  return (
    <div className="bg-white m-4 rounded-lg lg:w-full h-40 overflow-y-scroll">
      {tasks &&
        Object.values(tasks).map((task, i) => {
          return (
            <div >
              <div className="bg-gray-700 mb-2 mr-7 rounded-md py-3">
                <h1 className="ml-4 2xl:text-3xl md:text-0.7  text-white font-bold">
                  {task.name} Deliverables
                </h1>
              </div>
              <div className="grid lg:grid-cols-5 grid-cols-1 md:grid-cols-2 mb-4 gap-2 mx-2">
                {task.assets &&
                  Object.values(task.assets).map((asset, i) => {
                    return (
                      <div
                        onClick={() => {
                          setSelectedAsset(asset);
                          setOpenModel(true);
                        }}
                        className="bg-lightGray items-center rounded-xl p-1 flex flex-col cursor-pointer"
                      >
                        <div className="w-full 2xl:h-20rem md:h-15rem">
                          <video
                            src={asset.source}
                            preload="metadata"
                            className="w-full h-full rounded-t-lg object-cover"
                            controls={false}
                          />
                        </div>
                        <p className="text-black w-full md:text-xs font-extrabold text-center">
                          {asset.creator_name}
                        </p>

                        <CustomButton
                          className="mt-2 text-white px-7 py-1 outline-none text-xs"
                          onClick={() => {
                            setSelectedAsset(asset);
                            setOpenModel(true);
                          }}
                        >
                          View
                        </CustomButton>

                        {selectedAsset && (
                          <ViewVideo
                            open={openModel}
                            onClose={() => setOpenModel(false)}
                            asset={selectedAsset}
                            key={i}
                          />
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default FinalDeliverables;
